export const AnonymousPrincipal = "2vxsx-fae";

export const INFO_URL = "";

export const FREE_LIQUIDITY_NAME = "Free liquidity";

export enum ckBridgeChain {
  eth = "Ethereum",
  icp = "Internet Computer",
  btc = "Bitcoin",
}

export const NONE_SUB_HEX = "0000000000000000000000000000000000000000000000000000000000000000";

export enum ChartView {
  TVL,
  VOL,
  PRICE,
  FEES,
  LIQUIDITY,
  TRANSACTIONS,
  DexScreener,
  APR,
  DexTools,
}
